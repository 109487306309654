// import { Meta } from '../layout/Meta';
import { AppConfig } from '../utils/AppConfig';

const Base = ({children}:any) => (
  <div className="antialiased select-none font-sans">
    {/* <Meta title={AppConfig.title} description={AppConfig.description} /> */}
    {children}
  </div>
);

export { Base };
