import { MantineThemeOverride } from "@mantine/core";

export const AppConfig = {
  site_name: 'AQI.IN',
  title: 'AQI',
  description: 'Air Quality India',
  locale: 'en',
};

// return "#34a12b";
//   return "#d4cc0f";
//   return "#FFA500";
//   return "#FF69B4";
//   return "#800080";
// return "#ec0000";

export const myTheme: MantineThemeOverride = {
  colorScheme: 'light',
  colors: {
    '#34a12b':['#34a12b','#34a12b','#34a12b','#34a12b','#34a12b','#34a12b','#34a12b','#34a12b','#34a12b','#34a12b'],
    '#d4cc0f':['#d4cc0f','#d4cc0f','#d4cc0f','#d4cc0f','#d4cc0f','#d4cc0f','#d4cc0f','#d4cc0f','#d4cc0f','#d4cc0f'],
    '#FFA500':['#FFA500','#FFA500','#FFA500','#FFA500','#FFA500','#FFA500','#FFA500','#FFA500','#FFA500','#FFA500'],
    '#FF69B4':['#FF69B4','#FF69B4','#FF69B4','#FF69B4','#FF69B4','#FF69B4','#FF69B4','#FF69B4','#FF69B4','#FF69B4'],
    '#800080':['#800080','#800080','#800080','#800080','#800080','#800080','#800080','#800080','#800080','#800080'],
    '#ec0000':['#ec0000','#ec0000','#ec0000','#ec0000','#ec0000','#ec0000','#ec0000','#ec0000','#ec0000','#ec0000'],
    '#bbe0b8':['#bbe0b8','#bbe0b8','#bbe0b8','#bbe0b8','#bbe0b8','#bbe0b8','#bbe0b8','#bbe0b8','#bbe0b8','#bbe0b8'],
    '#e0dfb8':['#e0dfb8','#e0dfb8','#e0dfb8','#e0dfb8','#e0dfb8','#e0dfb8','#e0dfb8','#e0dfb8','#e0dfb8','#e0dfb8'],
    '#e0d2b8':['#e0d2b8','#e0d2b8','#e0d2b8','#e0d2b8','#e0d2b8','#e0d2b8','#e0d2b8','#e0d2b8','#e0d2b8','#e0d2b8'],
    '#e0b8cc':['#e0b8cc','#e0b8cc','#e0b8cc','#e0b8cc','#e0b8cc','#e0b8cc','#e0b8cc','#e0b8cc','#e0b8cc','#e0b8cc'],
    '#f0dbf0':['#f0dbf0','#f0dbf0','#f0dbf0','#f0dbf0','#f0dbf0','#f0dbf0','#f0dbf0','#f0dbf0','#f0dbf0','#f0dbf0'],
    '#e0b8b8':['#e0b8b8','#e0b8b8','#e0b8b8','#e0b8b8','#e0b8b8','#e0b8b8','#e0b8b8','#e0b8b8','#e0b8b8','#e0b8b8'],
    'ocean-blue': ['#2196F3','#2196F3','#2196F3','#2196F3','#2196F3','#2196F3','#2196F3','#2196F3','#2196F3','#2196F3',],
    'purple': ['#7D4CDB', '#6D3DC7', '#5D2EB3', '#4D1F9F', '#3D2093', '#2D217F', '#1D2273', '#0D2367', '#002461', '#002555'],
    'darkgreen': [
     "#ECF8EC",
      "#CBECCB",
      "#A9E0A9",
      "#87D387",
      "#66C766",
      "#44BB44",
      "#369636",
      "#297029",
      "#1B4B1B",
      "#0E250E"
    ],
    'yellow':[
      "#E8E8DB",
      "#DCDBBF",
      "#D4D3A1",
      "#D2D181",
      "#D8D65D",
      "#E6E333",
      "#FFFB00",
      "#CCC919",
      "#A6A428",
      "#898831",
    ],
   },
  primaryColor: 'ocean-blue',
  defaultRadius: 0,
  // fontFamily: 'kurale',
  // fontFamilyMonospace: 'Monaco, Courier, monospace',
  // headings: { fontFamily: 'Greycliff CF, sans-serif' },
};

export const colorscheme = {
  primary: '#2196F3',
  secondary: '#ff4081',
  success: '#4caf50',
  info: '#2196f3',
  warning: '#ff9800',
  danger: '#f44336',
  light: '#f4f4f4',
  dark: '#212121',
  'ocean-blue': ['#2196F3', '#2196F3','#2196F3','#2196F3','#2196F3','#2196F3','#2196F3','#2196F3','#2196F3','#2196F3',],
  'bright-pink': ['#F0BBDD', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82', '#AD1374'],
  'maroon': ['#F0BBDD', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82', '#AD1374'],
  'purple': ['#F0BBDD', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82', '#AD1374'],
  'darkgreen': ['#F0BBDD', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82', '#AD1374'],
  'yellowgreen': ['#F0BBDD', '#ED9BCF', '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82', '#AD1374'],
} as any;

