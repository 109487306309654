import { MantineProvider } from '@mantine/core';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';


import '../styles/global.css';
import { Base } from '../templates/Base';

import Playground from '../templates/Playground';
import { myTheme } from '../utils/AppConfig';
import { ModalsProvider } from '@mantine/modals';
import { useIsomorphicEffect } from "@mantine/hooks";
import axios from "axios";
import LZString from "lz-string";
import { addData } from "../store/data";
import {feature} from 'topojson-client'

const MyApp = ({ Component, pageProps }: AppProps) => {


  useIsomorphicEffect(() => {


    axios.get('/api/serveData')
      .then(res => {
        const dd = JSON.parse(LZString.decompressFromBase64(res.data))
        const geodd = feature(dd, dd.objects?.AdditionalIncluded)
        addData(geodd)
      }
      ).catch(err => {
        console.log('serveData ERROR ', err)
      }
      )

   }, []);




  return (
    <html>
      <Head>
        <title>Jaidad Finder</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />

      </Head>
      <Base>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={myTheme}
        >
          <ModalsProvider>

            <Playground />
          
          <Component {...pageProps} />

          </ModalsProvider>

        </MantineProvider>
      </Base>
    </html>
  )
};




export default MyApp;

