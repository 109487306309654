import { useEffect, useState } from 'react';
import {createStore} from 'redux';

const initialState = {
    data: {},
    loading: false,
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_DATA':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_DATA_SUCCESS':
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case 'FETCH_DATA_FAILURE':
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

const store = createStore(reducer);

const addData = (data) => {
    store.dispatch({
        type: 'FETCH_DATA_SUCCESS',
        payload: data
    })
}

const useData = () => {
    const [state,setState] = useState(store.getState());
    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            setState(store.getState());
        })
        
        return () => {
            unsubscribe();
        }
    }
    ,[])
    return state;
}

export {addData, useData};
